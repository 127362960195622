@keyframes fadeIn {
  0% {
    transform: translateY(-7%);
  }

  50% {
    transform: translateY(4%);
  }

  80% {
    transform: translateY(-2%);
  }

  95% {
    transform: translateY(1%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@font-face {
  font-family: 'LabradaSemiBold';
  src: url('/public/font/static/Labrada-SemiBold.ttf');
}

@font-face { 
  font-family: 'AbstractGroovy';
   src: url('/public/font/AbstractGroovy-K7Zdy.ttf');
}

.LabradaSemiBold {
  font-family: 'LabradaSemiBold';
  font-variant: small-caps;
  font-size: 16px;
  text-transform: capitalize;
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    transform: translate(-3px, -3px);
    text-shadow: 3px 3px 0 var(#00e3d0);
  }
  20% {
    transform: translate(-6px, -6px);
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80);
  }
  30% {
    transform: translate(-9px, -9px);
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80), 9px 9px var(#0b7e80);
  }
  40% {
    transform: translate(-12px, -12px);
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80), 9px 9px var(#0b7e80),
      12px 12px 0 var(#9f0f60e6);
  }
  50% {
    transform: translate(-12px, -12px);
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80), 9px 9px var(#0b7e80),
      12px 12px 0 var(#9f0f60e6);
  }
  60% {
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80), 9px 9px var(#0b7e80),
      12px 12px 0 var(#9f0f60e6);
  }
  70% {
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80), 9px 9px var(#0b7e80);
  }
  80% {
    text-shadow: 3px 3px 0 var(#00e3d0),
      6px 6px 0 var(#0b7e80);
  }
  90% {
    text-shadow: 3px 3px 0 var(#00e3d0);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glow {
  0%,100%{ text-shadow:0 0 4px #9f0f61; }
  25%{ text-shadow:0 0 4px #0b7e80; }
  50%{ text-shadow:0 0 4px #033eff; }
  75%{ text-shadow:0 0 4px #c005e1; }
}

@keyframes glowNoBlur {
  0%,100%{ text-shadow:0 0 4px #9f0f6199;filter: blur(.5px);}
  25%{ text-shadow:0 0 4px #0b7e8099;filter: blur(0); }
  50%{ text-shadow:0 0 4px #033eff99;filter: blur(.3px); }
  75%{ text-shadow:0 0 4px #c005e199;filter: blur(0); }
}

.AbstractGroovy {
  will-change: transform;
  font-family: 'AbstractGroovy';
  font-variant: small-caps;
  font-size: 16px;
  text-transform: capitalize;
  background-color: #f3ec78;
  background-image: linear-gradient(28deg,
    #0d1962 0%,
    #0d1962 18%,
    #033dff 49%,
    #9f0f60 68%, 
    #0d1962 100%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  animation:glowNoBlur 8.2s ease-in infinite;
}

.AbstractGroovyMobileMenuLogo {
  will-change: transform;
  font-family: 'AbstractGroovy';
  font-variant: small-caps;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  background-color: #f3ec78;
  background-image: linear-gradient(28deg,
    #0d1962 0%,
    #0d1962 18%,
    #033dff 49%,
    #9f0f60 68%, 
    #0d1962 100%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  animation:glowNoBlur 6.2s ease-in infinite;
}

svg {
  vertical-align: baseline;
}

button,
span {
  display: inline-flex;
  align-items: center;
}

body {
  width: 100%;
  height: 100%;
  background-color: #00e3d0;
  background-image: url('/public/bg.svg');
  background-size: cover; 
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation-direction: alternate;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  border-radius: .4em;
  box-shadow: 1 4px 12px rgba(0, 0, 0, 0.665);
  left: 0px;
  top: 0px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  overflow: hidden;
  z-index: 3;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.665);
}

.popup>.ant-menu-item {
  border-radius: 0px !important;
  padding: 0 16px 0 16px !important;
  margin: 0 !important;
  width: 100% !important;
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: all .8s;
  white-space: nowrap;
  -webkit-transition: all .8s;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: #ffffff95;
  border-radius: .7rem;
}


.ant-input-affix-wrapper {
  background-color: #ffffffe8;
  background: #ffffffe8;
  height: 32px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.48);
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  background-color: #fff0;
  background: #fff0;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.48);

}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  background-color: #fff0;
  background: #ffffffe8;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.48);

}

.ant-input-affix-wrapper > input.ant-input {
  background-color: #fff0;
  background: #fff0;
  backdrop-filter: blur(10px);
}

.popup li>i {
  margin-right: 8px;
}
.cardlist > .ant-spin-nested-loading > .ant-spin-container > .ant-row {
  margin: 0 !important;
}
.cardlist > .ant-spin-nested-loading>.ant-spin-container>.ant-row {
  display: flex;
  justify-content: space-evenly;
  padding: auto;
}
.decklist > .ant-spin-nested-loading > .ant-spin-container > .ant-row {
  margin: 0 !important;
}
.decklist > .ant-spin-nested-loading>.ant-spin-container>.ant-row {
  display: flex;
  justify-content: space-aroundde;
  padding: auto;
}

.ant-drawer .ant-drawer-wrapper-body {
  flex-direction: column-reverse;
}

::-webkit-scrollbar {
  height: 12px;
  width: 2px;
  background: #ffffff00;
}

::-webkit-scrollbar-thumb {
  background: #8f8f8fac;
}

.glassytopmenu {
  background-color: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-top: 1px solid rgba(255, 255, 255, 0.02);
}

.glassiphy {
  background-color: #ffffff75;
  background: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-top: 1px solid rgba(255, 255, 255, 0.02);
}

.glassybutton {
  background-color: #ffffff75;
  background: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-radius: .7em;
}

.glassyformcard {
  background-color: #ffffff75;
  background: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-radius: .7em;
}

.glassybadge {
  background-color: #ffffff75;
  background: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-radius: .7em;
}

.glassyfooter {
  background-color: #ffffff75;
  background: #ffffff75;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.48);
  border-right: 1px solid rgba(255, 255, 255, 0.02);
  border-left: 1px solid rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
}

.ant-descriptions-small .ant-descriptions-row>th,
.ant-descriptions-small .ant-descriptions-row>td {
  padding-bottom: 4px;
}

.ant-descriptions-item-content {
  padding-bottom: 4px;
}

li.ant-list-item>div:nth-child(1) {
  z-index: 2;
}

.ant-menu .ant-menu-item-group-title {
  padding: 0;
}
.ant-menu-light .ant-menu-item-selected, .ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: unset;
}

.ant-menu-horizontal .ant-menu-submenu {
  padding-inline: 0;
}

.ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item, .ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-inline: 0;
}

.ant-menu-item, .ant-menu-submenu-title {
  padding-inline: 8px !important;
}

.ant-menu-item-group {
  padding-inline: 8px !important;
}

.ant-list-grid .ant-col > .ant-list-item {
  margin-block-end: 4px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-X-hidden {
  overflow-x: hidden;
}

.ant-descriptions .ant-descriptions-header {
  margin-bottom: 8px;
}

.searchBarInput > .ant-input-wrapper > .ant-input-group-addon {
  top: -1px;
}

.risecard::before {
  will-change: transform;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: .7em;
  filter: blur(5px);
  mix-blend-mode:multiply;
  background: linear-gradient(28deg,
   #0d1962fc 0%,
   #0d1962e6 18%,
   #03ff7f 26%,
   #01edb3e6 34%,
   #00e3d0 38%,
   #0b7e80 44%,
   #033dfff2 49%,
   #bf05e1e6 58%,
   #9f0f60e6 68%, 
   #261762 82%, 
   #0d1962fc 100%);
}

pre {
  will-change: transform;
  color: white;
  border-radius: .7rem;
  position: relative;
  transition: 0.7s;
  cursor: pointer;
  border: none;
  margin: 0;
  transform-style: preserve-3d;
  transform: translateZ(var(--translateZ)) translateY(var(--translateY)) rotateY(var(--rotateX)) rotateX(var(--rotateY));
}

pre::before,
pre::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

pre::before {
  z-index: -1;
}

pre::after {
  z-index: -2;
}

.pre-container {
  will-change: transform;
  position: relative;
  display: grid;
  perspective: 1000px;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
}

.css-only pre {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  transition: all 0.5s;
}

.pre-container.css-only > div {
  z-index: 10;
  position: absolute;
}

.pre-container.css-only > div:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  inset: 0;
}

.pre-container.css-only > div:nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  inset: 0;
}

.pre-container.css-only > div:nth-child(3) {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  inset: 0;
}

.pre-container.css-only > div:nth-child(4) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  inset: 0;
}

.pre-container.css-only > div:nth-child(5) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  inset: 0;
}

.pre-container.css-only > div:nth-child(6) {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  inset: 0;
}

.pre-container.css-only > div:nth-child(7) {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  inset: 0;
}

.pre-container.css-only > div:nth-child(8) {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  inset: 0;
}

.pre-container.css-only > div:nth-child(9) {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  inset: 0;
}

.pre-container.css-only > div:nth-child(1):hover ~ pre {
  --rotateX: -18deg;
  --rotateY: 12deg;
  --translateZ: -10px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(2):hover ~ pre {
  --rotateX: 0deg;
  --rotateY: 12deg;
  --translateZ: 0px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(3):hover ~ pre {
  --rotateX: 18deg;
  --rotateY: 12deg;
  --translateZ: 10px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(4):hover ~ pre {
  --rotateX: -18deg;
  --rotateY: 0deg;
  --translateZ: -10px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(5):hover ~ pre {
  --rotateX: 0deg;
  --rotateY: 0deg;
  --translateZ: 0px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(6):hover ~ pre {
  --rotateX: 18deg;
  --rotateY: 0deg;
  --translateZ: 10px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(7):hover ~ pre {
  --rotateX: -14deg;
  --rotateY: -10deg;
  --translateZ: -10px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(8):hover ~ pre {
  --rotateX: 0deg;
  --rotateY: -10deg;
  --translateZ: 0px;
  --translateY: -12px;
}

.pre-container.css-only > div:nth-child(9):hover ~ pre {
  --rotateX: 14deg;
  --rotateY: -10deg;
  --translateZ: 10px;
  --translateY: -12px;
}

@media (min-width: 570px) {  
  .menubtn {
    margin-top: 20px;
    display: none;
  }

  .bigmenu {    
    margin-top: 10px;
    right: 10px;
  }

}

@media (max-width: 570px) {  
  .bigmenu {
    margin-top: 10px;
    display: none;
  }

  .menubtn {
    position: absolute;
    margin-top: 20px;
    right: 20px;    
  }
  .ant-list-pagination .ant-list-pagination-align-end {
    text-align: center;
  }
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.floatButtonMenu > .ant-float-btn-group-wrap {
  display: flex;
  flex-direction: column;
  margin-top: .7rem;
}

.floatButtonMenu > .ant-float-btn-square {
  border-radius: 0;
}
  
.floatButtonMenuPortrait > .floatButtonRoot {
  border-radius: .7em;
}

.floatButtonMenuLandscape > .floatButtonRoot {
  border-radius: .7em;
}

.floatButtonMenu > .floatButtonRoot {
  border-radius: .7em;
}

.ant-float-btn-body {
  max-width: 40px;
  max-height: 40px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev, .ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 20px;
  height: 20px;
  margin: 0;
  line-height: 20px;
}

.ant-list-pagination .ant-list-pagination-align-end {
  padding-right: 0px;
  margin-block-end: 64px;
}

.ant-list .ant-list-pagination {
  margin-block-start: 0px;
}
.ant-pagination::after {
  content: unset;
}
.ant-pagination-item-active {
  background-color: unset !important;
  border: none !important;
  border-color: none !important;
  cursor: default;
}
.ant-pagination-item {
  cursor: default;
}

.ant-menu-title-content {
  vertical-align: middle !important;
}
.ant-menu-item-icon {
  vertical-align: middle !important;
}

.gallery {
  --z: 32px;  /* control the zig-zag  */
  --s: 160px; /* control the size */
  --g: 0px;   /* control the gap */
  
  display: grid !important;
  gap: var(--g);
  width: calc(2*var(--s) + var(--g)) !important;
  grid-auto-flow: column;
  margin: 8px;
}
.gallery > img {
  width: 0;
  min-width: calc(100% + var(--z)/2);
  height: var(--s);
  object-fit: none;
  -webkit-mask: var(--mask);
          mask: var(--mask);
  cursor: default;
  transition: .6s;
  object-position: -90px -90px;
}
.gallery > img:hover {
  cursor: default;
  object-fit: none;
  object-position: -65px -60px;
  width: calc(var(--s) / 2);
}
.gallery > img:first-child {
  border-top-left-radius: .5em !important;
  border-bottom-left-radius: .5em !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  place-self: start;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
  --mask: linear-gradient(28deg, rgba(13,25,98,0.99) 0%, rgba(13,25,98,0.9) 18%, rgba(3,255,127,1) 26%, rgba(1,237,179,0.9) 34%, rgba(0,227,208,1) 38%, rgba(11,126,128,1) 44%, rgba(3,61,255,0.95) 49%, rgba(191,5,225,0.9) 58%, rgba(159,15,96,0.9) 68%, rgba(38,23,98,1) 82%, rgba(13,25,98,0.99) 100%), 
    conic-gradient(from -270deg at right,#0000,#000 1deg 9deg,#0000 45deg) 
      50%/100% calc(2*var(--z)) repeat-y;
}
.gallery > img:last-child {
  border-top-right-radius: .5em !important;
  border-bottom-right-radius: .5em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  place-self: end;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  --mask: linear-gradient(28deg, rgba(13,25,98,0.99) 0%, rgba(13,25,98,0.9) 18%, rgba(3,255,127,1) 26%, rgba(1,237,179,0.9) 34%, rgba(0,227,208,1) 38%, rgba(11,126,128,1) 44%, rgba(3,61,255,0.95) 49%, rgba(191,5,225,0.9) 58%, rgba(159,15,96,0.9) 68%, rgba(38,23,98,1) 82%, rgba(13,25,98,0.99) 100%),
    conic-gradient(from   270deg at left ,#0000,#000 1deg 16deg,#0000 45deg) 
      50% calc(50% - var(--z))/100% calc(2*var(--z)) repeat-y;
}